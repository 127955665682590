<template>
  <b-modal
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    size="xl"
    backdrop
    no-header
    right
    hide-header
    hide-footer
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">Add New Company</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Company Name"
                rules="required"
              >
                <b-form-group label="Company Name" label-for="full-name">
                  <b-form-input
                    id="full-name"
                    v-model="companyData.name"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Company Name"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="email"
                rules="required|email"
              >
                <b-form-group label="Email" label-for="email">
                  <b-form-input
                    id="email"
                    v-model="companyData.email"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="contactName"
              >
                <b-form-group
                  label="Contact"
                  label-for="user-role"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="user-role"
                    v-model="companyData.contactName"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <validation-provider #default="validationContext" name="phone">
                <b-form-group
                  label="Phone"
                  label-for="phone"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="phone"
                    v-model="companyData.phone"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="6">
              <validation-provider #default="validationContext" name="address">
                <b-form-group
                  label="Address"
                  label-for="address"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="address"
                    v-model="companyData.address"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="6">
              <validation-provider #default="validationContext" name="state">
                <b-form-group
                  label="state"
                  label-for="state"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="state"
                    v-model="companyData.state"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="6">
              <validation-provider #default="validationContext" name="country">
                <b-form-group
                  label="Country"
                  label-for="country"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="country"
                    v-model="companyData.country"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="description"
              >
                <b-form-group label="Description" label-for="username">
                  <b-form-textarea
                    rows="3"
                    max-rows="6"
                    id="username"
                    v-model="companyData.description"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-modal>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BRow,
  BFormTextarea,
  BCol,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
//import countries from '@/@fake-db/data/other/countries'
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BSidebar,
    ToastificationContent,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BRow,
    BCol,
    BFormTextarea,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewUserSidebarActive",
    event: "update:is-add-new-user-sidebar-active",
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
  },

  setup(props, { emit }) {
    const blankUserData = {
      name: "",
      description: "",
      contactName: "",
      email: "",
      phone: "",
      address: "",
      state: "",
      country: "",
    };

    const companyData = ref(JSON.parse(JSON.stringify(blankUserData)));
    const resetuserData = () => {
      companyData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const onSubmit = (event) => {
      event.preventDefault();
      store
        .dispatch("company/addUser", companyData.value)
        .then(() => {
          emit("refetch-data");
          emit("update:is-add-new-user-sidebar-active", false);
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.messages[0].messageText,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    return {
      companyData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
