import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {

        fetchUsers(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${process.env.VUE_APP_BASE_URL}/api/Company`, queryParams)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchJobs(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                   .get(`${process.env.VUE_APP_BASE_URL}/api/Job/getbycompany/${queryParams}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchCompanyId(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${process.env.VUE_APP_BASE_URL}/api/Company/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchJobId(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${process.env.VUE_APP_BASE_URL}/api/Job/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addUser(ctx, userData) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${process.env.VUE_APP_BASE_URL}/api/Company/create`, userData)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        editUser(ctx, userData) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`${process.env.VUE_APP_BASE_URL}/api/Company`, userData)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addPhoto(ctx, { id, base64 }) {
            var FormData = require('form-data');
            var data = new FormData();
            data.append('', base64);
            return new Promise((resolve, reject) => {
                axios
                    .post(`${process.env.VUE_APP_BASE_URL}/api/Company/update-logo/${id}`, data)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        delete(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`${process.env.VUE_APP_BASE_URL}/api/Company/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        deletePic(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${process.env.VUE_APP_BASE_URL}/api/Company/remove-logo/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchInterview(ctx, queryParams) {
            return new Promise((resolve, reject) => { 
              axios
                .post(`${process.env.VUE_APP_BASE_URL}/api/Interview`, queryParams)
                .then(response =>  resolve(response))
                .catch(error => reject(error))
            })
          },
          editInterview(ctx, userData) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`${process.env.VUE_APP_BASE_URL}/api/Interview`, userData)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        deleteInterview(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`${process.env.VUE_APP_BASE_URL}/api/Interview/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchPdf(ctx,data) {
            return new Promise((resolve, reject) => {
              axios
                .post(`${process.env.VUE_APP_BASE_URL}/api/Interview/GenerateCandidatePdfs`,data, {
                    headers: {
                        'Accept': 'application/pdf'
                      },
                  responseType: "blob"
                })
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
          },///api/Interview/setbyjob/{jobid}
          closeJob(ctx, jobid) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`${process.env.VUE_APP_BASE_URL}/api/Interview/setbyjob/${jobid}` )
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}